@import "./variable.scss";

$primary-color: map_get($base-colors, "primary-color");
$dark-primary-color: map_get($base-colors, "dark-primary-color");
$secondary-color: map_get($base-colors, "secondary-color");
$light_gray: map_get($font-colors, "light-gray");
$dark_gray: map_get($font-colors, "dark-gray");
$white: map_get($font-colors, "white");
$alto: map_get($font-colors, "alto");
$black: map_get($font-colors, "black");
$niagara: map_get($font-colors, "niagara");
$pastelGreen: map_get($font-colors, "pastel-green");

#icon,
#social-media-icon {
  color: $primary-color;
  padding-right: 5px;
  font-size: 18px;
  font-weight: 400;
  align-self: center;
}

.menu-drawer {
  .menu-container {
    width: 340px;

    .logo {
      width: 100%;
      margin: 0 auto;
      border: 1px solid transparent;

      .navbar-brand {
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;

        img {
          width: 50%;
          height: auto;
        }
        .close-icon {
          color: $secondary-color;
          position: absolute;
          top: 0;
          right: 20px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    ul > div {
      .catalog-accordian {
        width: 100%;
        box-shadow: none;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 400;
        color: $primary-color;

        &:hover {
          color: $secondary-color;
        }

        .catalog-menu {
          display: flex;
          flex-direction: column;
        }
      }

      .menu {
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 10px 15px;
        cursor: pointer;
        text-decoration: none;
        position: relative;
        color: $black;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.header {
  margin: 0 auto;

  .top-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background-color: $black;

    .logo {
      width: 90px;
      height: 80px;
      margin: 5px 0 10px 0;

      &:hover {
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .contact-container {
      font-family: "Trebuchet MS";
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 400;
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      color: $light_gray;

      .social-media-container {
        margin: 10px 0 10px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        a {
          text-decoration: none;
          color: $white;
          line-height: 0px;
          .icon {
            font-size: 25px;
            padding: 0 10px;

            &:hover {
              //color: $primary-color;
              cursor: pointer;
            }
          }
        }
      }

      .call-us-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $white;
        padding-right: 20px;

        .call-us-contact {
          padding-right: 10px;
        }

        strong {
          a {
            text-decoration: none;
            color: $white;
            font-weight: bold;
          }
        }
      }
    }

    .drawer-container {
      display: none;
    }
    .collapsible-menu-icon {
      display: none;
    }
  }

  .header-top-area {
    display: flex;
    flex-direction: row;
    background-color: $white;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $primary-color;
    height: 110px;
    padding: 0 40px;
    font-family: "Open Sans", sans-serif;
    color: $primary-color;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    .logo {
      width: 20%;
      height: 100%;

      img {
        width: 30%;
        height: auto;
        padding-left: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    Button {
      background-color: $primary-color;
      // color: $white;
      /* &:hover {
        background-color: $primary-color;
        border: 2px solid $secondary-color;
      }*/
    }

    .menu {
      font-family: "Trebuchet MS";
      font-size: 18px;
      letter-spacing: 1.5px;
      font-weight: 600;
      text-transform: capitalize;
      padding: 0 40px;
      cursor: pointer;
      text-decoration: none;
      color: $black;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &:hover {
        color: $primary-color;
      }
    }

    .active-menu {
      color: $secondary-color;
    }

    .user-area {
      padding-right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .login-button,
      .logout-button {
        border-right: 1px solid $alto;
        padding: 0 12px;
        cursor: pointer;
        font-family: serif;
      }

      .profile-icon {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        margin-right: 10px;
        cursor: pointer;
      }

      .customized-menu {
        margin-top: 10px;
      }

      .cart {
        padding-left: 10px;
        cursor: pointer;
        border-left: 1px solid $alto;

        .cart-icon {
          align-self: center;
        }
      }
    }
  }

  .fixed-header {
    position: fixed;
    width: 100%;
    top: 0%;
    z-index: 100;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    background-color: $black;
    color: $white;
    border-bottom: 0;

    .menu {
      color: $white;

      &:hover {
        color: $alto;
      }
    }

    .active-menu {
      color: $pastelGreen;
      font-weight: bold;
    }

    .login-button {
      color: $white;
    }
  }

  .header-button-container {
    display: flex;
    justify-content: space-around;
  }

  .button {
    height: 30px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
  }

  .login-button {
    background-color: transparent;
    color: $black;
    font-weight: 500;
    margin-left: -14%;

    &:hover {
      cursor: pointer;
      color: $primary-color;
    }
  }

  .sign-up-button {
    background-color: $primary-color;
    color: $white;
    border-radius: 5px;

    &:hover {
      background-color: $dark-primary-color;
    }
  }

  .is-logo {
    font-style: italic;
    font-family: "Brush Script MT";
    font-size: medium;
  }
  .logo-div {
    width: 10%;
    background-color: #ebedf1;
  }
}

.mega-menu {
  width: 70%;

  .catalog {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .menu {
    font-family: "Trebuchet MS";
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 20px 10px;
    cursor: pointer;
    text-decoration: none;
    color: $black;
    position: relative;

    &:hover {
      color: $primary-color;
    }
  }

  .mega-menu-panel {
    position: absolute;
    top: 45px;
    z-index: 100;
    width: 700px;
    min-height: 180px;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    animation: slide 10s ease 3.5s forwards;
    transition-delay: 5000ms;
    padding: 20px 0 0 20px;

    .loading {
      position: relative;
      min-height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .mega-menu-item-container {
      .mega-menu-item {
        text-align: left;
        width: 100%;
        padding-bottom: 20px;

        a {
          margin-bottom: 10px;
          font-size: 13px;
          font-weight: 600;
          text-transform: capitalize;
          cursor: pointer;
          text-decoration: none;
          color: $black;

          &:hover {
            color: $secondary-color;
          }
        }
        .mega-menu-sub-item-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 100%;
          padding-left: 20px;

          .mega-menu-sub-item {
            padding: 25px 10px 0 0;
            text-align: left;
            a {
              padding: 10px 0;
              font-weight: 200;
              color: #4b5662;
            }
          }
        }
      }
    }
  }
}
/**
Media Queries
 */

@media (min-width: 280px) and (max-width: 480px) {
  .header {
    .top-header-container {
      padding: 0 10px;

      .logo {
        width: 100%;

        img {
          width: 45%;
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 1053px) {
  .header {
    .top-header-container {
      padding: 0 10px;

      .logo {
        width: 30%;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .header {
    .top-header-container {
      height: 130px;
      .contact-container {
        display: none;
      }

      .drawer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: $black;
        height: inherit;
        width: 100%;

        .logo {
          height: auto;
        }

        .collapsible-menu-icon {
          display: block;
          padding: 20px 15px;
          cursor: pointer;
          color: $white;
        }
      }
    }

    .header-top-area {
      display: none;
    }
  }
}

@media only screen and (max-width: 800px) {
  .header {
    .header-top-area {
      padding: 0px;
      .logo {
        padding-left: 15px;
        width: 80%;
      }
      .search-box {
        //display: none;
      }
      .menu {
        font-size: 15px;
        font-weight: 600;
        font-family: "Trebuchet MS";
        text-transform: capitalize;
        padding: 12px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1053px) {
  .header {
    .header-top-area {
      min-height: 50px;
      position: relative;
      .logo {
        width: 80%;
        display: block;
        margin: 0px;
        margin-left: -5px;

        a {
          padding: 20px 0px;
        }
      }

      .menu {
        font-size: 15px;
        font-weight: 600;
        font-family: "Trebuchet MS";
        text-transform: capitalize;
        padding: 12px;
        cursor: pointer;
      }

      .user-area {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }
}

@media (min-width: 401px) and (max-width: 700px) {
  .header {
    .drawer-container {
      .logo {
        width: 100%;

        img {
          width: 30%;
          height: auto;
          padding-left: 10px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .header {
    .drawer-container {
      .logo {
        width: 100%;

        img {
          width: 20%;
          height: auto;
          padding-left: 10px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: 1053px) and (max-width: 1650px) {
  .header {
    .header-top-area {
      padding: 0 10px;

      .logo {
        width: 20%;

        img {
          width: 50%; 
        }
      }
    }
  }
}

@media (min-width: 1651px) and (max-width: 1920px){
  .header {
    .header-top-area {
      padding: 0 10px;

      .logo {
        width: 20%;

        img {
          width: 40%; 
        }
      }
    }
  }
}

@media (min-width: 1921px){
  .header {
    .header-top-area {
      padding: 0 10px;

      .logo {
        width: 20%;

        img {
          width: 30%; 
        }
      }
    }
  }
}
